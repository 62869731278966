<template>
    <div class="add-competitor" style="height: 100%;">
		<template v-if="hasLoaded">
			<div v-if="hasUnfilledSlots">
				<small class="text-muted list-headline g-no-selection">{{$t('competitors.addCompetitors.addCompetitor')}}</small>

				<div class="clearfix"></div>

				<div style="padding: 10px 0;" v-if="invalidDomain">
					<span class="text-danger">{{$t('competitors.addCompetitors.invalid')}}</span>
				</div>

				<input class="input-url" type="text" :placeholder="$t('competitors.addCompetitors.url')" style="display: block; margin-bottom: 20px;" v-model="customCompetitorUrl"/>

				<button class="add-from-url g-no-selection" @click="addCustomCompetitor()" :style="organizationBackgroundColor">
					<template v-if="isSubmitting">
						<span class="fa fa-spin fa-spinner fa-fw"></span>
						&nbsp;
					</template>
					{{$t('competitors.addCompetitors.add')}}
				</button>

				<div class="clearfix"></div>
			</div>

			<div class="row">
				<div class="col-lg-6 add-competitor-column">
					<small class="text-muted list-headline g-no-selection">
						{{$t('competitors.addCompetitors.addCompetitor')}}
					</small>

					<div @click="add(competitor)"
						 class="competitor--suggested g-no-selection"
						 :key="competitor.id"
						 v-for="competitor in suggestedCompetitors"
						:style="organizationBackgroundColor">
						<span class="competitor--name">{{competitor.domain}}</span>

						<div class="pull-right">
							<span class="fa fa-circle-o-notch fa-fw fa-spin" v-if="competitor.id === updating"></span>
							<span class="fa fa-plus fa-fw" v-else-if="hasUnfilledSlots"/>
						</div>
					</div>
				</div>

				<div class="col-lg-6 add-competitor-column">
					<small class="text-muted list-headline g-no-selection">
						{{$t('competitors.addCompetitors.chosenCompetitors')}}
						<span class="pull-right amountSelected" :style="positiveColor">
							{{selectedCompetitors.length}}/{{maximum}}
						</span>
					</small>

					<div @click="remove(competitor)" class="competitor--chosen g-no-selection active" :key="competitor.id" v-for="competitor in selectedCompetitors">
						<span class="competitor--name">{{competitor.domain}}</span>

						<div class="pull-right">
							<span class="fa fa-circle-o-notch fa-fw fa-spin" v-if="competitor.id === updating"></span>
							<span class="fa fa-remove fa-fw" v-else></span>
						</div>
					</div>

					<div class="competitor--chosen inactive g-no-selection" :class="{hide: !hasUnfilledSlots}">
						{{$t('competitors.addCompetitors.moreCompetitors')}}
					</div>
				</div>
			</div>
		</template>

		<div class="text-center" v-else>
			<span class="fa fa-circle-o-notch fa-fw fa-spin"></span>
		</div>

		<div class="clearfix"></div>
    </div>

</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_mixins.scss';

    .readme {
        margin-top: 30px;
        color: #888;
        font-size: 12px;
        border-top: 1px solid #f9f9f9;
        padding: 15px 10px;
        position: absolute;
        left: 50%;
        bottom: 40px;
        transform: translateX(-50%);
        min-width: 95%;
    }

    .input-url {
        background: #f5f5f5;
        border-radius: 5px;
        border: none;
        padding: 5px 12px;
        margin-top: 5px;
        width: 50%;
        float: left;
    }

    .add-from-url {
        border: none;
        background: #4096ee !important;
        color: #ffffff !important;
		border-color: transparent !important;
        border-radius: 3px;
        font-weight: 400;
        padding: 5px 12px;
        margin-top: 5px;
        margin-left: 5px;
    }

    .hide {
        display: none;
    }

    .list-headline {
        display: block;
        margin-bottom: 10px;
    }

    .competitor--suggested {
        background: #4096ee;
        color: #ffffff;
        padding: 5px 10px 0 10px;
        border-radius: 3px;
        margin-top: 5px;
        cursor: pointer;
        &.inactive {
            display: none;
        }
        .competitor--add {
            display: block;
            float: right;
            width: 18px;
            height: 18px;
            cursor: pointer;
            background-image: url('/images/competitors/add.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 70% 70%;
        }
    }

    .amountSelected {
        padding: 1px 8px;
    }

    .competitor--chosen {
        background: #f9f9f9;
        padding: 5px 10px 0 10px;
        border-radius: 3px;
        margin-top: 5px;
        &.inactive {
            border: 1px solid #f9f9f9;
            color: #c0c0c0;
            cursor: not-allowed;
            background: #fff;
            font-size: 12px;
            padding: 15px 15px;
        }
        &.active {
            opacity: 1;
            cursor: pointer;
        }
        .competitor--remove {
            display: block;
            float: right;
            width: 17px;
            height: 17px;
            cursor: pointer;
            background-image: url('/images/competitors/remove.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 70% 70%;
        }
    }

    .competitor--name {
        width: 80%;
        display: inline-block;
        @include ellipsis;
    }

    @media screen and (max-width: 1700px) {
        .add-competitor {
            .add-competitor-column {
                width: 100%;
                margin-bottom: 30px;
            }
        }
    }

    @media screen and (max-width: 991px) {
        .readme {
            margin-top: 30px;
            color: #888;
            font-size: 12px;
            border-top: 1px solid #f9f9f9;
            padding: 15px 10px;
            position: relative;
            min-width: 95%;
        }
    }

</style>

<script>
    import CompetitorService from '@/services/competitors/CompetitorService'
    import Card from '@/app/shared/components/Card'
    import MixpanelService from '@/services/mixpanel/MixpanelService'
	import {mapGetters} from "vuex";

    const PunyCode = require('punycode')

    export default {
        data() {
            return {
                customCompetitorUrl: null,
                hasLoaded: false,
                isSubmitting: false,
                invalidDomain: false,
                competitors: [],
                maximum: 3,
                updating: null,
                tooltip: {
                    headlineText: $t('competitors.addCompetitors.tooltip.headline'),
                    paragraphText: $t('competitors.addCompetitors.tooltip.paragraph'),
                    subParagraphText: $t('competitors.addCompetitors.tooltip.subParagraph'),
                    unorderedList: [$t('competitors.addCompetitors.tooltip.unorderedList.item1'), $t('competitors.addCompetitors.tooltip.unorderedList.item2')],
                    columnSizes: {
                        lg: 12,
                        md: 12,
                        sm: 12,
                        xs: 12
                    }
                }
            }
        },

        watch: {
            customCompetitorUrl() {
                this.invalidDomain = false
            }
        },

        computed: {
            formattedCompetitors() {
                return this.competitors.map((competitor) => {
                    competitor.domain = PunyCode.toUnicode(competitor.domain)
                    return competitor
                })
            },

            organizationBackgroundColor() {
                return {backgroundColor:$org('colors.standard.secondary.hex')}
            },

            positiveColor() {
                if (this.hasUnfilledSlots) {
                    return
                }

                return {
                    color: $org('colors.standard.statuses.positive.tertiary'),
                    background: $org('colors.standard.statuses.positive.primary'),
                    borderRadius: '3px',
                    padding: '1px 8px'
                }
            },

            suggestedCompetitors() {
                return this.formattedCompetitors.filter((competitor) => {
                    return competitor.suggested
                })
            },

            selectedCompetitors() {
                return this.formattedCompetitors.filter((competitor) => {
                    return !competitor.suggested
                })
            },

            hasUnfilledSlots() {
                return this.selectedCompetitors.length < this.maximum
            },

			...mapGetters('customer', {
				customer: 'getCustomer'
			}),
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                this.hasLoaded = false

                const options = {
                    filter_groups: [
                        {
                            or: true,
                            filters: [
                                {
                                    key: 'isRelevant',
                                    operator: 'eq',
                                    value: true
                                },
                                {
                                    key: 'suggested',
                                    operator: 'eq',
                                    value: false
                                },
								{
									key: 'customer',
									operator: 'eq',
									value: this.customer.id
								}
                            ]
                        }
                    ]
                }

                CompetitorService.findAll(options, response => {
                    const data = response.data

                    this.competitors = data.rows
                    this.hasLoaded = true
                })
            },

            addCustomCompetitor() {
                // Adding a custom competitor from input url
                if (this.isSubmitting || !this.hasUnfilledSlots || !this.customCompetitorUrl) {
                    return
                }

                this.isSubmitting = true
                this.invalidDomain = false

				let url = this.customCompetitorUrl

				if (! url.includes('http')) {
					url = `https://${url}`
				}

				const domain = (new URL(url));

                CompetitorService.createFromDomain(domain.hostname, response => {
                    const data = response.data

                    this.load()
                    this.isSubmitting = false

                    MixpanelService.track('Competitors - Manage - Add', {type: 'custom', domain: data.domain})

                    eventHub.$emit('competitors.updated')
                }, error => {
                    this.isSubmitting = false
                    this.invalidDomain = true
                })
            },

            add(competitor) {
                // Adding a customer by suggestion click
                if (this.updating || !this.hasUnfilledSlots) {
                    return
                }

                this.updating = competitor.id

                CompetitorService.put(competitor.id, {suggested: false}, (response) => {
                    const data = response.data

                    this.updating = null

                    this.$set(this.competitors, competitor.id, data)

                    MixpanelService.track('Competitors - Manage - Add', {type: 'suggested', domain: competitor.domain})

                    eventHub.$emit('competitors.updated')

                    this.load()
                })
            },

            remove(competitor) {
                if (this.updating) {
                    return
                }

                this.updating = competitor.id

                CompetitorService.put(competitor.id, {suggested: true}, (response) => {
                    const data = response.data

                    this.updating = null

                    this.$set(this.competitors, competitor.id, data)

                    MixpanelService.track('Competitors - Manage - Remove', {domain: competitor.domain})

                    eventHub.$emit('competitors.updated')

                    this.load()
                })
            }
        },

        components: {
            Card
        }
    }
</script>
