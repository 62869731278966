const BaseService = require('@/services/BaseService')

const namespace = 'host'

const findByDomain = (domain, options, cb, errorCb) => {
    BaseService.get(`${namespace}/${domain}`, options, cb, errorCb)
}

const findByIdentity = (options, cb, errorCb) => {
    BaseService.get(namespace, options, cb, errorCb)
}

export default {
    findByDomain,
    findByIdentity,
}
