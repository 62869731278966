const BaseService = require('@/services/BaseService')

const namespace = 'competitor/metric-value/customer'

const find = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
    BaseService.get(namespace, options, cb, errorCb)
}

const industryTopMetrics = (cb, errorCb) => {
    BaseService.get(namespace + '/industry-top-metrics', {}, cb, errorCb)
}

export default {
    find,
    findAll,
    industryTopMetrics
}
