<template>
	<div>
		<card :headline="$t('competitors.box.title')"
			  :description="$t('competitors.box.subtitle')"
			  class="competitor-wrapper"
			  icon="pe-7s-piggy">
			<template v-slot:header>
				<button v-if="! refreshed"
						type="button"
						class="manage-btn"
						@click="onManageCompetitors">
					<i class="fa fa-refresh fa-fw" />&nbsp;
					<span>{{ $t('competitors.addCompetitors.description') }}</span>
				</button>

				<template v-if="isGhostIdentity">
					&nbsp;
					<button v-if="! refreshed"
							type="button"
							class="refresh-btn"
							@click="onRefresh">
						<i class="fa fa-refresh fa-fw" />
					</button>
				</template>
			</template>

			<div class="report">
				<div class="comparison-header-wrapper">
					<div class="comparison-header">
						<div v-if="!! customer">
							<div class="fav-icon">
								<img :src="`https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${customer.domain}&size=32`" />
							</div>
							<div class="title">{{ customer.name }}</div>
							<div class="domain">{{ customer.domain }}</div>
						</div>

						<div v-for="competitor in competitors">
							<div class="fav-icon">
								<img :src="`https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${competitor.domain}&size=32`" />
							</div>
							<div class="title">{{ competitor.domain }}</div>
							<div class="domain">{{ $t('competitors.grid.competitor') }}</div>
						</div>
					</div>
				</div>

				<div class="section">
					<div class="section-title">
						<div>
							<div class="title">{{ $t('competitors.grid.reputation.title') }}</div>

							<div v-if="!! customer" class="score" />
							<div v-for="competitor in competitors" :key="`score-${competitor.id}`" class="score" />
						</div>
					</div>
				</div>

				<div class="comparison-table">
					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.reputation.average') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<template v-if="averageReview(customerMetrics()) !== null">
								{{ averageReview(customerMetrics()).toFixed(1) }} / 5
							</template>

							<span v-else>-</span>
						</div>

						<div v-for="competitor in competitors"
							 :key="`avg-reputation-${competitor.id}`"
							 class="metric">
							<template v-if="averageReview(competitorMetrics(competitor.id)) !== null">
								{{ averageReview(competitorMetrics(competitor.id)).toFixed(1) }} / 5
							</template>

							<span v-else>-</span>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.reputation.count') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							{{ formatNumber(numberOfRatings(customerMetrics())) }}
						</div>

						<div v-for="competitor in competitors"
							 :key="`total-reviews-${competitor.id}`"
							 class="metric">
							{{ formatNumber(numberOfRatings(competitorMetrics(competitor.id))) }}
						</div>
					</div>

					<div class="comparison-table-row sub">
						<div class="title">
							{{ $t('competitors.grid.reputation.google') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<div v-if="ratingStars(customerMetrics(), 'google.average') !== null"
								 class="rating"
								 v-html="ratingStars(customerMetrics(), 'google.average')">
							</div>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`reputation-google-${competitor.id}`"
							 class="metric">
							<div v-if="ratingStars(competitorMetrics(competitor.id), 'google.average') !== null"
								 class="rating"
								 v-html="ratingStars(competitorMetrics(competitor.id), 'google.average')">
							</div>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row sub">
						<div class="title">
							{{ $t('competitors.grid.reputation.trustpilot') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<div v-if="ratingStars(customerMetrics(), 'trustpilot.average') !== null"
								 class="rating"
								 v-html="ratingStars(customerMetrics(), 'trustpilot.average')">
							</div>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`trustpilot-${competitor.id}`"
							 class="metric">
							<div v-if="ratingStars(competitorMetrics(competitor.id), 'trustpilot.average') !== null"
								 class="rating"
								 v-html="ratingStars(competitorMetrics(competitor.id), 'trustpilot.average')">
							</div>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>
				</div>

				<div class="section">
					<div class="section-title">
						<div>
							<div class="title">{{ $t('competitors.grid.website.title') }}</div>

							<div v-if="!! customer" class="score" />
							<div v-for="competitor in competitors" :key="`score-${competitor.id}`" class="score" />
						</div>
					</div>
				</div>

				<div class="comparison-table">
					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.website.screenshot') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<img v-if="value(customerMetrics(), 'website', 'screenshot') !== null"
								 :src="value(customerMetrics(), 'website', 'screenshot')"
								 class="screenshot"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`screenshot-${competitor.id}`"
							 class="metric">
							<strong v-if="value(competitorMetrics(competitor.id), 'website', 'screenshot') !== null">
								<img :src="value(competitorMetrics(competitor.id), 'website', 'screenshot')"
									 class="screenshot"
								/>
							</strong>

							<i v-else
							   class="fa fa-spinner fa-spin"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.website.googlePageSpeed') }}
						</div>

						<div v-if="!! customer"
							 class="metric"
							 :class="benchmarkClassAbove(customerMetrics(), 'website', 'speed-score', .8)">
							<strong v-if="percentage(customerMetrics(), 'website', 'speed-score') !== null">
								{{ percentage(customerMetrics(), 'website', 'speed-score') }} %
							</strong>

							<i v-else
							   class="fa fa-spinner fa-spin"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`page-speed-${competitor.id}`"
							 class="metric"
							 :class="benchmarkClassAbove(competitorMetrics(competitor.id), 'website', 'speed-score', .8)">
							<strong v-if="percentage(competitorMetrics(competitor.id), 'website', 'speed-score') !== null">
								{{ percentage(competitorMetrics(competitor.id), 'website', 'speed-score') }} %
							</strong>

							<i v-else
							   class="fa fa-spinner fa-spin"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.website.responsive') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<template v-if="flag(customerMetrics(), 'website', 'mobile-friendly') !== null">
								<i v-if="flag(customerMetrics(), 'website', 'mobile-friendly') === true"
								   class="fa fa-check fa-fw"
								/>

								<i v-else
								   class="fa fa-check fa-fw"
								/>
							</template>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`mobile-friendly-${competitor.id}`"
							 class="metric">
							<template v-if="flag(competitorMetrics(competitor.id), 'website', 'mobile-friendly') !== null">
								<i v-if="flag(competitorMetrics(competitor.id), 'website', 'mobile-friendly') === true"
								   class="fa fa-check fa-fw"
								/>

								<i v-else
								   class="fa fa-check fa-fw"
								/>
							</template>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.website.speedScore') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<div v-if="numberValue(customerMetrics(), 'website', 'load-time') !== null"
								 class="speed">
								<div class="border" />
								<div class="bar"
									 :style="{ left: `${scoreBar(customerMetrics(), 'website', 'load-time')}%` }"
								/>
							</div>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`speed-score-${competitor.id}`"
							 class="metric">
							<div v-if="numberValue(competitorMetrics(competitor.id), 'website', 'load-time') !== null"
								 class="speed">
								<div class="border" />
								<div class="bar"
									 :style="{ left: `${scoreBar(competitorMetrics(competitor.id), 'website', 'load-time')}%` }"
								/>
							</div>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row sub">
						<div class="title">
							{{ $t('competitors.grid.website.loadSpeed') }}
						</div>

						<div v-if="!! customer"
							 class="metric"
							 :class="benchmarkClassBelow(customerMetrics(), 'website', 'load-time', 2500)">
							<strong v-if="numberValue(customerMetrics(), 'website', 'load-time') !== null">
								{{ (numberValue(customerMetrics(), 'website', 'load-time') / 1000).toFixed(2) }} s
							</strong>

							<i v-else
							   class="fa fa-spinner fa-spin"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`speed-${competitor.id}`"
							 class="metric"
							 :class="benchmarkClassBelow(competitorMetrics(competitor.id), 'website', 'load-time', 2500)">
							<strong v-if="numberValue(competitorMetrics(competitor.id), 'website', 'load-time') !== null">
								{{ (numberValue(competitorMetrics(competitor.id), 'website', 'load-time') / 1000).toFixed(2) }} s
							</strong>

							<i v-else
							   class="fa fa-spinner fa-spin"
							/>
						</div>
					</div>

					<div class="comparison-table-row sub">
						<div class="title">
							{{ $t('competitors.grid.website.dropoff') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<strong v-if="dropoffSpeed(customerMetrics()) !== null">
								{{ formatNumber(dropoffSpeed(customerMetrics())) }} {{ $t('competitors.grid.visits') }}
							</strong>

							<i v-else
							   class="fa fa-spinner fa-spin"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`drop-off-${competitor.id}`"
							 class="metric">
							<strong v-if="dropoffSpeed(competitorMetrics(competitor.id)) !== null">
								{{ formatNumber(dropoffSpeed(competitorMetrics(competitor.id))) }} {{ $t('competitors.grid.visits') }}
							</strong>

							<i v-else
							   class="fa fa-spinner fa-spin"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.website.ssl') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<i v-if="flag(customerMetrics(), 'website', 'ssl') === true"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(customerMetrics(), 'website', 'ssl') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`ssl-${competitor.id}`"
							 class="metric">
							<i v-if="flag(customerMetrics(), 'website', 'ssl') === true"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(customerMetrics(), 'website', 'ssl') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.website.consoleErrors') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<i v-if="flag(customerMetrics(), 'website', 'console-errors') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(customerMetrics(), 'website', 'console-errors') === true"
							   class="fa fa-remove fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`competitor-${competitor.id}`"
							 class="metric">
							<i v-if="flag(competitorMetrics(competitor.id), 'website', 'console-errors') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(competitorMetrics(competitor.id), 'website', 'console-errors') === true"
							   class="fa fa-remove fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.website.server') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<div v-if="value(customerMetrics(), 'website', 'server') !== null">
								{{ value(customerMetrics(), 'website', 'server') }}
							</div>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`server-${competitor.id}`"
							 class="metric">
							<div v-if="value(competitorMetrics(competitor.id), 'website', 'server') !== null">
								{{ value(competitorMetrics(competitor.id), 'website', 'server') }}
							</div>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.website.cms') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<template v-if="value(customerMetrics(), 'website', 'cms') !== null">
								<i v-if="value(customerMetrics(), 'website', 'cms') !== ''"
								   class="fa fa-check fa-fw"
								/>

								<i v-else
								   class="fa fa-check fa-fw"
								/>
							</template>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`cms-${competitor.id}`"
							 class="metric">
							<template v-if="value(competitorMetrics(competitor.id), 'website', 'cms') !== null">
								<i v-if="value(competitorMetrics(competitor.id), 'website', 'cms') !== ''"
								   class="fa fa-check fa-fw"
								/>

								<i v-else
								   class="fa fa-check fa-fw"
								/>
							</template>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row sub">
						<div class="title">
							{{ $t('competitors.grid.website.platform') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<template v-if="value(customerMetrics(), 'website', 'cms') !== null">
								{{ value(customerMetrics(), 'website', 'cms') || '-' }}
							</template>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`cms-${competitor.id}`"
							 class="metric">
							<template v-if="value(competitorMetrics(competitor.id), 'website', 'cms') !== null">
								{{ value(competitorMetrics(competitor.id), 'website', 'cms') || '-' }}
							</template>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.website.misspellings') }}
						</div>
						<div v-if="!! customer"
							 class="metric">
							<i v-if="flag(customerMetrics(), 'website', 'misspelling') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(customerMetrics(), 'website', 'misspelling') === true"
							   class="fa fa-remove fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`broken-links-${competitor.id}`"
							 class="metric">
							<i v-if="flag(competitorMetrics(competitor.id), 'website', 'misspelling') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(competitorMetrics(competitor.id), 'website', 'misspelling') === true"
							   class="fa fa-remove fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>
				</div>

				<div class="section">
					<div class="section-title">
						<div>
							<div class="title">{{ $t('competitors.grid.seo.title') }}</div>

							<div v-if="!! customer" class="score" />
							<div v-for="competitor in competitors" :key="`score-${competitor.id}`" class="score" />
						</div>
					</div>
				</div>

				<div class="comparison-table">
					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.seo.pageScore') }}
						</div>

						<div v-if="!! customer"
							 class="metric"
							 :class="benchmarkClassAbove(customerMetrics(), 'website', 'onpage_score', 80)">
							<strong v-if="numberValue(customerMetrics(), 'website', 'onpage_score') !== null">
								{{ numberValue(customerMetrics(), 'website', 'onpage_score').toFixed(1) }} %
							</strong>

							<i v-else
							   class="fa fa-spinner fa-spin"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`page-speed-${competitor.id}`"
							 class="metric"
							 :class="benchmarkClassAbove(competitorMetrics(competitor.id), 'website', 'onpage_score', 80)">
							<strong v-if="numberValue(competitorMetrics(competitor.id), 'website', 'onpage_score') !== null">
								{{ numberValue(competitorMetrics(competitor.id), 'website', 'onpage_score').toFixed(1) }} %
							</strong>

							<i v-else
							   class="fa fa-spinner fa-spin"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.seo.keywords') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<span v-if="numberValue(customerMetrics(), 'seo', 'organic.keywords') !== null">
								{{ formatNumber(numberValue(customerMetrics(), 'seo', 'organic.keywords')) }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`keywords-${competitor.id}`"
							 class="metric">
							<span v-if="numberValue(competitorMetrics(competitor.id), 'seo', 'organic.keywords') !== null">
								{{ formatNumber(numberValue(competitorMetrics(competitor.id), 'seo', 'organic.keywords')) }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row sub">
						<div class="title">
							{{ $t('competitors.grid.seo.keywords1') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<span v-if="numberValue(customerMetrics(), 'seo', 'organic.keywords_pos_1') !== null">
								{{ formatNumber(numberValue(customerMetrics(), 'seo', 'organic.keywords_pos_1')) }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`keywords-${competitor.id}`"
							 class="metric">
							<span v-if="numberValue(competitorMetrics(competitor.id), 'seo', 'organic.keywords_pos_1') !== null">
								{{ formatNumber(numberValue(competitorMetrics(competitor.id), 'seo', 'organic.keywords_pos_1')) }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row sub">
						<div class="title">
							{{ $t('competitors.grid.seo.keywords2-3') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<span v-if="numberValue(customerMetrics(), 'seo', 'organic.keywords_pos_2_3') !== null">
								{{ formatNumber(numberValue(customerMetrics(), 'seo', 'organic.keywords_pos_2_3')) }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`keywords-${competitor.id}`"
							 class="metric">
							<span v-if="numberValue(competitorMetrics(competitor.id), 'seo', 'organic.keywords_pos_2_3') !== null">
								{{ formatNumber(numberValue(competitorMetrics(competitor.id), 'seo', 'organic.keywords_pos_2_3')) }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row sub">
						<div class="title">
							{{ $t('competitors.grid.seo.keywords4-10') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<span v-if="numberValue(customerMetrics(), 'seo', 'organic.keywords_pos_4_10') !== null">
								{{ formatNumber(numberValue(customerMetrics(), 'seo', 'organic.keywords_pos_4_10')) }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`keywords-${competitor.id}`"
							 class="metric">
							<span v-if="numberValue(competitorMetrics(competitor.id), 'seo', 'organic.keywords_pos_4_10') !== null">
								{{ formatNumber(numberValue(competitorMetrics(competitor.id), 'seo', 'organic.keywords_pos_4_10')) }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.seo.backlinks') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<span v-if="numberValue(customerMetrics(), 'seo', 'backlinks') !== null">
								{{ formatNumber(numberValue(customerMetrics(), 'seo', 'backlinks')) }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`backlinks-${competitor.id}`"
							 class="metric">
							<span v-if="numberValue(competitorMetrics(competitor.id), 'seo', 'backlinks') !== null">
								{{ formatNumber(numberValue(competitorMetrics(competitor.id), 'seo', 'backlinks')) }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.seo.traffic') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<span v-if="numberValue(customerMetrics(), 'seo', 'organic.traffic') !== null">
								{{ formatNumber(numberValue(customerMetrics(), 'seo', 'organic.traffic')) }} {{ $t('competitors.grid.visits') }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`traffic-${competitor.id}`"
							 class="metric">
							<span v-if="numberValue(competitorMetrics(competitor.id), 'seo', 'organic.traffic') !== null">
								{{ formatNumber(numberValue(competitorMetrics(competitor.id), 'seo', 'organic.traffic')) }} {{ $t('competitors.grid.visits') }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.seo.value') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<span v-if="numberValue(customerMetrics(), 'seo', 'organic.cost') !== null">
								{{ formatNumber(numberValue(customerMetrics(), 'seo', 'organic.cost'), {}, true) }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`value-${competitor.id}`"
							 class="metric">
							<span v-if="numberValue(competitorMetrics(competitor.id), 'seo', 'organic.cost') !== null">
								{{ formatNumber(numberValue(competitorMetrics(competitor.id), 'seo', 'organic.cost'), {}, true) }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.seo.brokenLinks') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<i v-if="flag(customerMetrics(), 'website', 'broken_links') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(customerMetrics(), 'website', 'broken_links') === true"
							   class="fa fa-remove fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`broken-links-${competitor.id}`"
							 class="metric">
							<i v-if="flag(competitorMetrics(competitor.id), 'website', 'broken_links') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(competitorMetrics(competitor.id), 'website', 'broken_links') === true"
							   class="fa fa-remove fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.seo.sitemap') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<i v-if="flag(customerMetrics(), 'website', 'sitemap') === true"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(customerMetrics(), 'website', 'sitemap') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`sitemap-${competitor.id}`"
							 class="metric">
							<i v-if="flag(competitorMetrics(competitor.id), 'website', 'sitemap') === true"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(competitorMetrics(competitor.id), 'website', 'sitemap') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.seo.robots') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<i v-if="flag(customerMetrics(), 'website', 'robots') === true"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(customerMetrics(), 'website', 'robots') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`robots-${competitor.id}`"
							 class="metric">
							<i v-if="flag(competitorMetrics(competitor.id), 'website', 'robots') === true"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(competitorMetrics(competitor.id), 'website', 'robots') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.seo.favicon') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<i v-if="flag(customerMetrics(), 'website', 'favicon') === true"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(customerMetrics(), 'website', 'favicon') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`favicon-${competitor.id}`"
							 class="metric">
							<i v-if="flag(competitorMetrics(competitor.id), 'website', 'favicon') === true"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(competitorMetrics(competitor.id), 'website', 'favicon') === false"
							   class="fa fa-check fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>
				</div>

				<div class="section">
					<div class="section-title">
						<div>
							<div class="title">{{ $t('competitors.grid.paid.title') }}</div>

							<div v-if="!! customer" class="score" />
							<div v-for="competitor in competitors" :key="`score-${competitor.id}`" class="score" />
						</div>
					</div>
				</div>

				<div class="comparison-table">
					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.paid.spend') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<strong v-if="numberValue(customerMetrics(), 'seo', 'paid.cost') !== null">
								{{ formatNumber(numberValue(customerMetrics(), 'seo', 'paid.cost'), {}, true) }}
							</strong>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`spend-${competitor.id}`"
							 class="metric">
							<strong v-if="numberValue(competitorMetrics(competitor.id), 'seo', 'paid.cost') !== null">
								{{ formatNumber(numberValue(competitorMetrics(competitor.id), 'seo', 'paid.cost'), {}, true) }}
							</strong>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.paid.traffic') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<span v-if="numberValue(customerMetrics(), 'seo', 'paid.traffic') !== null">
								{{ formatNumber(numberValue(customerMetrics(), 'seo', 'paid.traffic')) }} {{ $t('competitors.grid.visits') }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`traffic-${competitor.id}`"
							 class="metric">
							<span v-if="numberValue(competitorMetrics(competitor.id), 'seo', 'paid.traffic') !== null">
								{{ formatNumber(numberValue(competitorMetrics(competitor.id), 'seo', 'paid.traffic')) }} {{ $t('competitors.grid.visits') }}
							</span>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>
				</div>

				<div class="section">
					<div class="section-title">
						<div>
							<div class="title">{{ $t('competitors.grid.email.title') }}</div>

							<div v-if="!! customer" class="score" />
							<div v-for="competitor in competitors" :key="`score-${competitor.id}`" class="score" />
						</div>
					</div>
				</div>

				<div class="comparison-table">
					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.email.dmarc') }}
						</div>

						<div v-if="!! customer"
							 class="metric">

							<i v-if="flag(customerMetrics(), 'email', 'dmarc') === true"
							   class="fa fa-check fa-fw"
							/>
							<i v-else-if="flag(customerMetrics(), 'email', 'dmarc') === false"
							   class="fa fa-remove fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`dmarc-${competitor.id}`"
							 class="metric">

							<i v-if="flag(competitorMetrics(competitor.id), 'email', 'dmarc') === true"
							   class="fa fa-check fa-fw"
							/>
							<i v-else-if="flag(competitorMetrics(competitor.id), 'email', 'dmarc') === false"
							   class="fa fa-remove fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>

					<div class="comparison-table-row">
						<div class="title">
							{{ $t('competitors.grid.email.spf') }}
						</div>

						<div v-if="!! customer"
							 class="metric">
							<i v-if="flag(customerMetrics(), 'email', 'spf') === true"
							   class="fa fa-check fa-fw"
							/>

							<i v-else-if="flag(customerMetrics(), 'email', 'spf') === false"
							   class="fa fa-remove fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>

						<div v-for="competitor in competitors"
							 :key="`dmarc-${competitor.id}`"
							 class="metric">

							<i v-if="flag(competitorMetrics(competitor.id), 'email', 'spf') === true"
							   class="fa fa-check fa-fw"
							/>
							<i v-else-if="flag(competitorMetrics(competitor.id), 'email', 'spf') === false"
							   class="fa fa-remove fa-fw"
							/>

							<i v-else
							   class="fa fa-spinner fa-spin fa-fw"
							/>
						</div>
					</div>
				</div>
			</div>
		</card>

		<modal ref="manageCompetitors">
			<div class="modal-body">
				<manage-competitors v-if="hasLoadedCompetitors" />
			</div>
		</modal>
	</div>
</template>


<script>
import CompetitorService from '@/services/competitors/CompetitorService'
import CustomerMetricService from '@/services/competitors/CustomerMetricService'
import CompetitorMetricService from '@/services/competitors/CompetitorMetricService'
import HostService from '@/services/host/HostService'
import AnalyticsService from '@/services/_app/google-analytics/AnalyticsService'
import ManageCompetitors from '@/app/competitors/components/ManageCompetitors'
import hasIntegration from '@/mixins/integrations/hasIntegration'

import { mapGetters } from 'vuex'
import datepickerMixin from '@/app/datepicker/datepicker.mixin'
import moment from 'moment'

const PunyCode = require('punycode')

import Modal from "@/app/layout/components/Modal";

export default {
	mixins: [hasIntegration, datepickerMixin],

	data () {
		return {
			internalCompetitors: {},
			analyticsVisitors: null,
			hasLoadedCompetitors: false,
			refreshInterval: null,

			competitors: [],
			metrics: null,
			refreshed: false,
			manageCompetitors: false,
		}
	},

	computed: {
		...mapGetters('identity', {
			identity: 'getIdentity'
		}),

		...mapGetters('customer', {
			customer: 'getCustomer'
		}),

		...mapGetters('ghost', {
			isGhostIdentity: 'isGhost'
		}),

		positiveColor () {
			return { color: $org('colors.standard.statuses.positive.focus') }
		},

		negativeColor () {
			return { color: $org('colors.standard.statuses.negative.focus') }
		}
	},

	mounted () {
		this.refreshInterval = setInterval(this.loadMetrics, 10000)

		this.load()

		eventHub.$on('competitors.updated', this.reset)
	},

	beforeDestroy () {
		eventHub.$off('competitors.updated', this.reset)

		if (!! this.refreshInterval) {
			clearInterval(this.refreshInterval)
			this.refreshInterval = null
		}
	},

	methods: {
		formatNumber(value, options = {}, currency = false) {
			const defaults = {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0
			}

			if (isNaN(value)) {
				return value
			}

			const currencies = {
				DKK: {
					conversion: .1428
				},

				SEK: {
					conversion: .09185
				},

				USD: {
					conversion: 1,
				},

				EUR: {
					conversion: 1.06227
				}
			}

			const mappings = {
				da: 'DKK',
				fi: 'EUR',
				sv: 'SEK'
			}

			if (currency) {
				defaults.style = 'currency'
				defaults.currency = !! mappings[this.$i18n.locale] ? mappings[this.$i18n.locale] : 'USD'

				value = value / currencies[defaults.currency].conversion
			}

			return new Intl.NumberFormat(this.$i18n.locale, {
				...defaults,
				...options
			}).format(value)
		},

		onRefresh() {
			this.refreshed = true

			CompetitorService.refresh()
		},

		reset() {
			this.internalCompetitors = {}

			this.load()
		},

		load () {
			this.loadCustomer()
			this.loadCompetitors()
			this.loadAnalyticsTraffic()
			this.loadMetrics()
		},

		loadMetrics() {
			CompetitorService.metrics((response) => {
				this.metrics = response.data
			}, () => {})
		},

		async loadAnalyticsTraffic () {
			if (!this.hasIntegration('Google analytics')) {
				return
			}

			const from = moment().subtract(1, 'month')
			const to = moment()

			const data = AnalyticsService.sessions(from, to)
			this.analyticsVisitors = ! data.total ? null : data.total
		},

		loadCustomer () {
			const identity = this.identity

			if (! identity || ! this.customer) {
				return
			}
		},

		loadCompetitors () {
			const options = {
				filter_groups: [
					{
						filters: [
							{
								key: 'suggested',
								operator: 'eq',
								value: false
							},
							{
								key: 'customer',
								operator: 'eq',
								value: this.customer.id
							}
						]
					}
				]
			}

			CompetitorService.findAll(options, response => {
				const data = response.data
				this.competitors = data.rows

				this.hasLoadedCompetitors = true
			})
		},

		numberOfRatings(metrics, source = null) {
			const slugs = [
				'trustpilot.count',
				'google.count'
			]

			return metrics.reduce(
				(carry, item) => {
					if (source === null && item.area === 'review' && slugs.includes(item.name)) {
						carry += Number(item.value)
					}

					if (source !== null && item.area === 'review' && item.name === source) {
						carry += Number(item.value)
					}

					return carry
				},
				0
			)
		},

		averageReview(metrics) {
			const reviews = []

			// Google

			const googleReviews = metrics.find(metric => metric.area === 'review' && metric.name === 'google.count')
			const googleAverage = metrics.find(metric => metric.area === 'review' && metric.name === 'google.average')

			if (!! googleReviews && !! googleAverage) {
				for(let i = 0; i < Number(googleReviews.value); i++) {
					reviews.push(Number(googleAverage.value))
				}
			}

			// Trustpilot

			const trustpilotReviews = metrics.find(metric => metric.area === 'review' && metric.name === 'trustpilot.count')
			const trustpilotAverage = metrics.find(metric => metric.area === 'review' && metric.name === 'trustpilot.average')

			if (!! trustpilotReviews && !! trustpilotAverage) {
				for(let i = 0; i < Number(trustpilotReviews.value); i++) {
					reviews.push(Number(trustpilotAverage.value))
				}
			}

			if (! reviews.length) {
				return null
			}

			return reviews.reduce((carry, item) => carry + item, 0) / reviews.length
		},

		ratingStars(metrics, source) {
			const match = metrics.find(metric => metric.area === 'review' && metric.name === source)

			if (! match) {
				return null
			}

			const value = Number(match.value)

			let whole = Math.floor(value)

			if ((value - whole) > .8) {
				whole++
			}

			const half = value - whole > .35
			const rest = 5 - whole - half

			const parts = []

			for (let i = 0; i < whole; i++) {
				parts.push(`<i class="fa fa-star fa-fw"></i>`)
			}

			for (let i = 0; i < half; i++) {
				parts.push(`<i class="fa fa-star-half-empty"></i>`)
			}

			for (let i = 0; i < rest; i++) {
				parts.push(`<i class="fa fa-star-o"></i>`)
			}

			parts.push(`<div class="ratings">(${this.numberOfRatings(metrics, source.replace('average', 'count'))})</div>`)

			return parts.join('')
		},

		translateMetrics (metric) {
			return $t('competitors.metrics.verbs.' + metric)
		},

		companyMetricValue (company, metric) {
			const metricValues = company.metricValues || []

			const match = metricValues.find(metricValue => {
				return metricValue.name === metric
			})

			if (match && match.name === 'pageSpeed') {
				return `${match.value} / 100`
			}

			return match ? match.value : '-'
		},

		customerHasAnalytics () {
			return !! this.analyticsVisitors
		},

		flag(metrics, area, name) {
			const match = metrics.find(metric => metric.area === area && metric.name === name)

			if (! match) {
				return null
			}

			return Number(match.value) === 1
		},

		benchmarkClassAbove(metrics, area, name, threshold) {
			const match = metrics.find(metric => metric.area === area && metric.name === name)

			if (! match) {
				return null
			}

			return Number(match.value) > threshold
				? 'positive'
				: 'negative'
		},

		benchmarkClassBelow(metrics, area, name, threshold) {
			const match = metrics.find(metric => metric.area === area && metric.name === name)

			if (! match) {
				return null
			}

			return Number(match.value) <= threshold
				? 'positive'
				: 'negative'
		},

		percentage(metrics, area, name) {
			const match = metrics.find(metric => metric.area === area && metric.name === name)

			if (! match) {
				return null
			}

			return Number(Number(match.value) * 100).toFixed(1)
		},

		numberValue(metrics, area, name) {
			const match = metrics.find(metric => metric.area === area && metric.name === name)

			if (! match) {
				return null
			}

			return Number(match.value)
		},

		value(metrics, area, name) {
			const match = metrics.find(metric => metric.area === area && metric.name === name)

			if (! match) {
				return null
			}

			return match.value
		},

		scoreBar(metrics, area, name) {
			const match = metrics.find(metric => metric.area === area && metric.name === name)

			if (! match) {
				return null
			}

			const value = Number(match.value)

			if (value === 0) {
				return 0
			}

			const score = value / 5000 * 100

			return score < 100
				? score
				: 100
		},

		dropoffSpeed(metrics) {
			const organicTraffic = metrics.find(metric => metric.area === 'seo' && metric.name === 'organic.traffic')
			const paidTraffic = metrics.find(metric => metric.area === 'seo' && metric.name === 'paid.traffic')
			const loadTime = metrics.find(metric => metric.area === 'website' && metric.name === 'load-time')

			if (! organicTraffic || ! paidTraffic || ! loadTime) {
				return null
			}

			const totalTraffic = Number(organicTraffic.value) + Number(paidTraffic.value)

			const time = Number(loadTime.value) / 1000

			if (time >= 4) {
				return Math.floor(totalTraffic * .6)
			}

			if (time >= 3) {
				return Math.floor(totalTraffic * .27)
			}

			if (time >= 2) {
				return Math.floor(totalTraffic * .1)
			}

			if (time >= 1) {
				return Math.floor(totalTraffic * .04)
			}

			if (time >= .5) {
				return Math.floor(totalTraffic * .02)
			}

			return 0
		},

		customerMetrics() {
			const metrics = this.metrics || {}
			return metrics.customer || []
		},

		competitorMetrics(id) {
			const metrics = this.metrics || {}
			const competitors = metrics.competitors || {}

			return competitors[id] || []
		},

		onManageCompetitors() {
			jQuery(this.$refs.manageCompetitors.$el).modal('show')
		}
	},

	components: {
		ManageCompetitors,
		Modal
	}
}
</script>

<style lang="scss" scoped>
.competitor-wrapper {
	overflow: visible !important;
}

.report {
	> div.comparison-header-wrapper {
		position: sticky;
		top: 0;
		z-index: 99;

		> div.comparison-header {
			display: flex;
			padding: 15px 0;
			border-bottom: 4px solid #eee;
			background-color: #fff;

			&:before {
				width: 320px;
				min-width: 320px;
				content: '';
			}

			> * {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				flex-direction: column;

				flex: 1;

				> div.fav-icon {
					margin-bottom: 10px;

					> img {
						width: 26px;
						height: 26px;
						object-fit: contain;
					}
				}

				> .title {
					color: #000;
					font-weight: 500;
					font-size: 14px;
					margin: 0;
					padding: 0;
				}

				> .domain {
					color: #5d5d5d;
					font-weight: 400;
					font-size: 13px;
				}
			}
		}
	}

	> div.section {
		> div.section-title {
			> div {
				display: flex;
				color: #2a2a2a;
				font-size: 15px;
				font-weight: 700;
				letter-spacing: .1px;
				background-color: #f8f8f8;
				border-bottom: 1px solid #d9d9d9;

				> div.title {
					width: 320px;
					min-width: 320px;
					font-size: 15px;
					font-weight: 600;
					padding: 15px;
				}

				> div.score {
					flex: 1;

					display: flex;
					align-items: center;
					justify-content: center;
					gap: 4px;

					> div {
						position: relative;
						width: 15px;
						height: 15px;
						border-radius: 50%;
						border: 2px solid #27c454;

						&.full {
							&:after {
								position: absolute;
								top: 2px;
								right: 2px;
								bottom: 2px;
								left: 2px;
								content: '';
								border-radius: 50%;
								background-color: #27c454;
							}
						}
					}

					&.warning {
						> div {
							border: 2px solid #eea236;

							&.full {
								&:after {
									background-color: #eea236;
								}
							}
						}
					}

					&.negative {
						> div {
							border: 2px solid #ee3636;

							&.full {
								&:after {
									background-color: #ee3636;
								}
							}
						}
					}
				}
			}
		}
	}

	> div.comparison-table {
		display: flex;
		flex-direction: column;

		> div.comparison-table-row {
			display: flex;
			border-bottom: 1px solid #eee;

			&.sub {
				> div.title {
					color: #676767;
					font-weight: 400;
					font-size: 13px;
					padding-left: 40px;
				}

				> div.metric {
					color: #676767;
					font-size: 13px;
				}
			}

			> div.title {
				width: 320px;
				min-width: 320px;

				padding: 15px;

				font-size: 14px;
				font-weight: 500;
			}

			> div.metric {
				flex: 1;

				padding: 15px 0;

				display: flex;
				gap: 6px;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				strong {
					font-weight: 500;
				}

				&.positive {
					color: #27c454;
					font-weight: 500;
				}

				&.negative {
					color: #ee3636;
					font-weight: 500;
				}

				.fa-check,
				.fa-remove {
					font-size: 17px;
				}

				.fa-check {
					color: #27c454;
				}

				.fa-remove {
					color: #ee3636;
				}

				> div.speed {
					position: relative;
					width: 60px;
					height: 10px;
					border-radius: 20px;
					background-color: red;
					margin: 5px 0;

					background-image: linear-gradient(to right, #27c454, #eea236, #ee3636);

					> div.bar {
						position: absolute;
						top: 5px;
						width: 4px;
						height: 15px;
						background-color: #4d4d4d;
						border-left: 1px solid #fff;
						border-right: 1px solid #fff;
						transform: translateX(-50%) translateY(-50%);
						border-radius: 20px;
					}

					> div.border {
						position: absolute;
						top: 1px;
						right: 1px;
						bottom: 1px;
						left: 1px;
						border: 1px solid #fff !important;
						border-radius: 20px;
					}
				}

				> div.rating {
					display: flex;
					align-items: center;
					gap: 2px;
					color: #eea236;
					font-size: 14px;

					> div.ratings {
						color: #7a7a7a;
						font-weight: 400;
						font-size: 13px;
					}
				}
			}
		}
	}
}


@media (max-width: 1100px) {
	.report {
		> div.comparison-header-wrapper {
			> div.comparison-header {
				&:before {
					display: none;
				}

				> * {
					> div.fav-icon {
						margin-bottom: 6px;

						> img {
							width: 18px;
							height: 18px;
							object-fit: contain;
						}
					}

					> .title {
						font-size: 12px;
					}

					> .domain {
						font-size: 11px;
					}
				}
			}
		}

		> div.section {
			> div.section-title {
				> div {
					flex-wrap: wrap;

					> div.title {
						flex-basis: 100%;
						text-align: center;
					}

					> div.score {
						flex: 1;

						padding: 0 0 15px 0;
					}
				}
			}
		}

		> div.comparison-table {
			> div.comparison-table-row {
				flex-wrap: wrap;

				> div.title {
					flex-basis: 100%;
					text-align: center;
					border-bottom: 1px solid #eee;
				}

				> div.metric {
					border-right: 1px solid #eee;

					&:last-child {
						border-right: 0;
					}

					> div.rating {
						flex-wrap: wrap;
						justify-content: center;

						> div.ratings {
							flex-basis: 100%;
							text-align: center;
						}
					}
				}
			}
		}
	}
}

.screenshot {
	width: 250px;
	max-width: calc(100% - 30px);
	border-radius: 16px;
	border: 2px solid #fff;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.manage-btn {
	color: #fff !important;
	font-weight: 600 !important;
	font-size: 13px !important;
	padding: 12px 14px !important;
	border-radius: 6px !important;
	outline: 0 !important;
	box-shadow: none !important;
	border: 0 !important;
	letter-spacing: -.2px !important;
	background-color: #000;
}

.refresh-btn {
	color: #fff !important;
	font-weight: 600 !important;
	font-size: 13px !important;
	padding: 12px 14px !important;
	border-radius: 6px !important;
	outline: 0 !important;
	box-shadow: none !important;
	border: 0 !important;
	letter-spacing: -.2px !important;
	background-color: #000;
}
</style>
