const BaseService = require('@/services/BaseService')

const namespace = 'competitor'

const find = (id, options, cb, errorCb) => {
    BaseService.get(namespace + '/' + id, options, cb, errorCb)
}

const findAll = (options, cb, errorCb) => {
    BaseService.get(namespace, options, cb, errorCb)
}

const put = (id, data, cb, errorCb) => {
    BaseService.put(namespace + '/' + id, data, cb, errorCb)
}

const createFromDomain = (domain, cb, errorCb) => {
    BaseService.post(namespace + '/' + domain, {}, cb, errorCb)
}

const metrics = (cb, errorCb) => {
    BaseService.get(namespace + '/metrics', {}, cb, errorCb)
}

const refresh = (cb, errorCb) => {
    BaseService.post(namespace + '/refresh', {}, cb, errorCb)
}

export default {
    find,
    findAll,
    put,
    createFromDomain,
	metrics,
	refresh,
}
